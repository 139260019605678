import styled from 'styled-components';

const Container = styled.div`
	background-color: var(--theme-ui-colors-background, #1d2330);
	color: var(--theme-ui-colors-text, #f5f6f7);
	display: flex;
	flex: 1;
	height: 100%;
	padding: 2rem;
	width: 100%;
`;

export default Container;
