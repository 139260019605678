import React, { FC } from 'react';
import { Header } from 'gatsbyThemeDoczComponents/Header';
import Container from './styles';

const Layout: FC = ({ children }) => {
	return (
		<>
			<Header />
			<Container>{children}</Container>
		</>
	);
};

export default Layout;
